export * from "ce/IDE/hooks/useParentEntityInfo";

import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import { IDE_TYPE, type IDEType } from "ee/IDE/Interfaces/IDETypes";
import { getCurrentModuleId } from "ee/selectors/modulesSelector";
import { getCurrentPackageId } from "ee/selectors/packageSelectors";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";
import { useSelector } from "react-redux";
import {
  getCurrentApplicationId,
  getCurrentBasePageId,
} from "selectors/editorSelectors";

export const useParentEntityInfo = (ideType: IDEType) => {
  const appId = useSelector(getCurrentApplicationId);
  const basePageId = useSelector(getCurrentBasePageId);
  const packageId = useSelector(getCurrentPackageId);
  const moduleId = useSelector(getCurrentModuleId);
  const workflowId = useSelector(getCurrentWorkflowId);

  switch (ideType) {
    case IDE_TYPE.Package:
      return {
        editorId: packageId || "",
        parentEntityId: moduleId || "",
        parentEntityType: ActionParentEntityType.MODULE,
      };
    case IDE_TYPE.Workflow:
      return {
        editorId: workflowId || "",
        parentEntityId: workflowId || "",
        parentEntityType: ActionParentEntityType.WORKFLOW,
      };
    default:
      return {
        editorId: appId || "",
        parentEntityId: basePageId || "",
        parentEntityType: ActionParentEntityType.PAGE,
      };
  }
};
